<template>
  <div class="login-container">
    <!-- <div class="login-icon">
      <img src="https://oss.syounggroup.com/static/file/soyoung-zg/aliyun/soyoung-zg/website/logoLevel/logoName_190_60_2.png" />
      <p>
        成就每一个客户
        <span>商家登录</span>
      </p>
    </div> -->
    <div class="middle">
      <oss-image
        class="middle-bg"
        src="https://oss.syounggroup.com/static/file/soyoung-zg/official-website/login-bg.png"
      />
      <div class="login-form-container">
        <p class="type">
          <span :class="type === 'code' ? 'active' : ''" @click="typeChange('code')"
            >验证码登录</span
          >
          |
          <span :class="type === 'password' ? 'active' : ''" @click="typeChange('password')"
            >密码登录</span
          >
          <router-link to="/syoung/register/step"
            ><span class="type-right">注册账号</span></router-link
          >
        </p>
        <el-form
          :model="loginForms"
          :rules="loginRule"
          @submit.native.prevent="handleLoginWithCode"
          autocomplete="on"
          label-position="left"
          ref="loginForms"
        >
          <el-form-item prop="username">
            <!-- <i class="iconfont icon-yonghudenglu_zhigongguangwang svg-container"></i> -->
            <el-input
              name="username"
              placeholder="请输入手机号"
              type="text"
              v-model="loginForms.username"
            />
          </el-form-item>
          <el-form-item prop="verificationCode" v-if="type === 'code'" class="marginbttom-25">
            <!-- <i class="iconfont icon-mima_zhigongguangwang svg-container"></i> -->
            <el-input
              name="verificationCode"
              placeholder="请输入验证码"
              type="text"
              maxlength="4"
              v-model="loginForms.verificationCode"
            ></el-input>
            <getVerificationCode
              :mobile="loginForms.username"
              :handleCallback="mobileCheck"
              class="get-code"
            />
          </el-form-item>
          <el-form-item prop="password" v-else class="marginbttom-25">
            <!-- <i class="iconfont icon-mima_zhigongguangwang svg-container"></i> -->
            <el-input
              name="password"
              placeholder="请输入密码"
              type="password"
              v-model="loginForms.password"
            />
          </el-form-item>
          <div class="form-row">
            <el-checkbox v-model="agreest" @change="agreestChange">
              <span class="form-row--txetbox">已阅读并同意</span></el-checkbox
            >

            <span class="link-type" @click="openProtocol('xy')">《平台使用协议》</span>和

            <span class="link-type" @click="openProtocol('gf')">《分销商管理规范》</span>、
            <span class="link-type" @click="openProtocol('ys')">《隐私协议》</span>
          </div>
          <el-form-item class="submit-btn-div">
            <el-button :loading="loading" class="submit-btn" native-type="submit" type="primary"
              >登录</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </div>

    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogVisibleProtocol"
      top="10vh"
      :before-close="() => (dialogVisibleProtocol = false)"
    >
      <div class="textBox">
        <!-- <iframe :src="iframeSrc" frameborder="0" width="100%" height="100%"></iframe> -->
        <agreement :agreementType="agreementType" />
      </div>
    </el-dialog>
  </div>
</template>

<script>
import getVerificationCode from '@/components/getVerificationCode';
import { memberAuthMobileVerify, authLoginVerify } from '@/api/login';
import agreement from '@/views/agreement/index.vue';
import { validatePhoneSimple } from '@/common/validator';
import { mapActions } from 'vuex';
import { getItem, setItem } from '@/utils/localStorage';
import { getItem as getItemCookie } from '@/utils/cookie';
import {
  removeItem as sessionStorageRemoveItem,
  getItem as sessionStorageGetItem,
} from '@/utils/sessionStorage';
// const errMsg = '当前手机号码暂未开启登录权限，请先联系专属顾问开启账号登录权限';
const authLoginVerifyFn = () => {
  return new Promise((resolve, reject) => {
    authLoginVerify()
      .then(response => {
        resolve();
        // if (response && response.data) {
        //   resolve();
        // } else {
        //   console.log('errMsg', errMsg);
        //   reject(errMsg);
        // }
      })
      .catch(error => {
        console.log('error', error);
        reject(error);
      });
  });
};
export default {
  name: 'login',
  components: { getVerificationCode, agreement },
  data() {
    return {
      type: 'code',
      loginForms: {
        username: '',
        verificationCode: '',
        password: '',
      },
      tips: '获取验证码',
      loginRule: {
        username: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { validator: validatePhoneSimple, trigger: 'blur' },
        ],
        verificationCode: [{ required: true, message: '请输入验证码', trigger: 'blur' }],
        password: [{ required: true, message: '请输入密码', trigger: 'blur' }],
      },
      loading: false,
      agreest: false, // 是否同意平台使用协议
      dialogVisibleProtocol: false,
      agreementType: '',
      dialogTitle: '',
      mobileFlag: false,
      fromPath: '',
    };
  },
  created() {
    if (getItem('agreest')) {
      this.agreest = getItem('agreest');
    }
  },
  methods: {
    ...mapActions([
      'user',
      'GetAuthenticateInfo',
      'getCountStatisticsHot',
      'chat/updateUp',
      'LogOut',
    ]),
    openProtocol(type) {
      const typeObj = {
        xy: 'PLATFORM_AGREEMENT',
        gf: 'DISTRIBUTOR_MANAGEMENT',
        ys: 'PRIVACY_AGREEMENT',
      };
      const titleObj = {
        xy: '平台使用协议',
        gf: '分销商管理规范',
        ys: '隐私协议',
      };
      this.agreementType = typeObj[type];
      this.dialogTitle = titleObj[type];
      this.dialogVisibleProtocol = true;
    },
    agreestChange(val) {
      setItem('agreest', val);
    },
    // 登录
    handleLoginWithCode() {
      if (!this.agreest) {
        this.$message.info('请勾选并阅读同意协议');
        return;
      }
      this.$refs.loginForms.validate(async valid => {
        if (valid) {
          this.loginLoading = true;
          await this.mobileCheck();
          if (this.mobileFlag) {
            const loginType = this.type === 'code' ? 'LoginWithCode' : 'Login';
            this.$store
              .dispatch(loginType, this.loginForms)
              .then(() => {
                return authLoginVerifyFn().catch(e => {
                  this.LogOut();
                  return Promise.reject();
                });
              })
              .then(res => {
                return this.GetAuthenticateInfo();
              })
              .then(res => {
                if (sessionStorageGetItem('sightseerMemberId')) {
                  // 登录成功删除游客模式MemberId
                  sessionStorageRemoveItem('sightseerMemberId');
                }
                this['chat/updateUp'](); // 更新客服key
                if (
                  getItemCookie('aS') &&
                  (getItemCookie('aS').status === 'PASS' ||
                    getItemCookie('aS').status === 'DISABLE')
                ) {
                  this.getCountStatisticsHot();
                  if (this.$route.query.redirect) {
                    this.$router.replace(this.$route.query.redirect);
                  } else {
                    // this.$router.go(-1);
                    this.$router.replace(this.fromPath);
                  }
                } else {
                  this.$router.replace('/syoung/register/step');
                }
              })
              .catch(res => {
                if (res) {
                  res.msg && this.$message.error(res.msg);
                  this.LogOut();
                }
              })
              .finally(() => {
                this.loginLoading = false;
              });
          } else {
            this.loginLoading = false;
          }
        } else {
          return false;
        }
      });
    },
    // 验证码 密码切换
    typeChange(val) {
      this.type = val;
      if (this.type === 'code') {
        this.loginForms.password = '';
      } else {
        this.loginForms.verificationCode = '';
      }
      this.$refs['loginForms'].clearValidate();
    },
    // 是否注册
    async mobileCheck() {
      const p = {
        mobile: this.loginForms.username,
        verifyType: 'LOGIN', // LOGIN:手机号登录验证;REGISTER:手机号注册验证,可用值:LOGIN,REGISTER
      };
      await memberAuthMobileVerify(p);
      this.mobileFlag = true;
      // if (!mobileFlag) {
      //   this.$confirm('您的手机号码尚未注册，请先注册', '提示', {
      //     confirmButtonText: '确定',
      //     cancelButtonText: '取消',
      //     type: 'warning',
      //   })
      //     .then(() => {
      //       this.$router.push({
      //         path: '/syoung/register/step',
      //         query: { mobile: this.loginForms.username },
      //       });
      //     })
      //     .catch(() => {
      //       this.$message({
      //         type: 'info',
      //         message: '已取消',
      //       });
      //     });
      //   return;
      // }
      return true;
    },
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.fromPath = from.path;
    });
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
$bg: #2d3a4b;
$dark_gray: #889aa4;
.login-container {
  .login-icon {
    height: 114px;
    padding-left: 15%;
    padding-top: 25px;
    background: #fff;
    img {
      width: 160px;
      height: 44px;
      display: block;
    }
    p {
      font-size: 18px;
      color: #6d6d6d;
      letter-spacing: 3px;
      margin-top: 10px;
      span {
        color: #d80b30;
        margin-left: 28px;
        font-weight: bold;
      }
    }
  }
  .top {
    height: 114px;
    padding-left: 15%;
    padding-top: 25px;
    img {
      width: 160px;
      height: 44px;
      display: block;
    }
    p {
      font-size: 18px;
      color: #6d6d6d;
      letter-spacing: 3px;
      margin-top: 10px;
      span {
        color: #d80b30;
        margin-left: 28px;
        font-weight: bold;
      }
    }
  }
  .middle {
    position: relative;
    .middle-bg {
      width: 100%;
    }
    .login-form-container {
      position: absolute;
      top: 10%;
      right: 17.7%;
      min-width: 380px;
      min-height: 346px;
      width: 19.8%;
      height: 65.3%;
      background: #fff;
      border: 1px solid #e4e4e4;
      border-radius: 10px;
      padding: 0 32px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .type {
        font-size: 14px;
        margin: 0 0 12% 0;
        span {
          color: #505050;
          cursor: pointer;
          &.active {
            color: #d80b30;
          }
        }
        &-right {
          float: right;
          &:hover {
            color: #d80b30;
          }
        }
      }
      .register {
        text-align: center;
        margin-bottom: 22px;
        span {
          cursor: pointer;
          &:first-of-type {
            margin-right: 40px;
          }
          &:hover {
            color: #409eff;
          }
        }
      }
      .submit-btn-div {
        border: none;
        margin-bottom: 10px;
      }
      .submit-btn {
        width: 100%;
      }
    }
  }
  /deep/ {
    .el-input {
      display: inline-block;
      height: 47px;
      width: 85%;
      input {
        background: transparent;
        border: 0px;
        -webkit-appearance: none;
        border-radius: 0px;
        padding: 12px 5px 12px 15px;
        height: 47px;
      }
    }
    .el-form-item {
      border: 1px solid #c9c9c9;
      border-radius: 5px;
      color: #454545;
      margin-bottom: 36px;
      &.marginbttom-25 {
        margin-bottom: 25px;
      }
    }
  }

  .tips {
    font-size: 14px;
    color: #fff;
    margin-bottom: 10px;
    span {
      &:first-of-type {
        margin-right: 16px;
      }
    }
  }
  .svg-container {
    width: 20px;
    height: 20px;
    color: #dadada;
    vertical-align: middle;
    padding-left: 10px;
    font-size: 30px;
  }
  .show-pwd {
    position: absolute;
    right: 10px;
    top: 7px;
    font-size: 16px;
    color: $dark_gray;
    cursor: pointer;
    user-select: none;
  }
  .get-code {
    position: absolute;
    right: 10px;
    top: 0;
    font-size: 12px;
    user-select: none;
    border: none;
    height: 47px;
    line-height: 47px;
    padding: 0;
    background: transparent;
  }
}
.form-row {
  margin-bottom: 8px;
  .el-checkbox {
    /* margin-right: 10px; */
    /deep/ .el-checkbox__label {
      padding-left: 0;
    }
  }
  &--txetbox {
    font-size: 12px;
    margin-left: 5px;
    .link-type {
      margin-left: 5px;
    }
  }
}
.textBox {
  text-align: justify;
  .mainBox {
    padding: 0 10px 50px 10px;
    .mainBox-title {
      font-weight: 600;
      font-size: 14px;
      margin: 5px 0;
      text-align: center;
      display: block;
    }
  }
}
.submit-btn-text {
  text-align: center;
  color: #666;
}
</style>
